@import url(https://fonts.googleapis.com/css?family=Girassol|IM+Fell+French+Canon+SC&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{font-family:'Girassol', cursive;overflow-x:hidden;letter-spacing:0.06em}

.Card_dropDown__2plW2{color:white}.Card_dropDown__2plW2:hover{cursor:pointer;text-decoration:underline}

.Dashboard_container__26o_0{width:100vw;overflow-x:hidden;display:flex;flex-direction:column;background-color:black;align-items:center;justify-content:flex-start}.Dashboard_container__26o_0 .Dashboard_card__3uEpE{width:90%;margin:34px;overflow-x:hidden}.Dashboard_broken__1vEIZ{height:100vh;background-color:black}@media only screen and (min-width: 375px){.Dashboard_container__26o_0 .Dashboard_card__3uEpE{margin-left:70px;height:90%}}@media only screen and (min-width: 425px){.Dashboard_container__26o_0 .Dashboard_card__3uEpE{width:100%;margin-left:160px;height:90%}}@media only screen and (min-width: 768px){.Dashboard_container__26o_0{flex-direction:row;display:flex;flex-wrap:wrap}.Dashboard_container__26o_0 .Dashboard_card__3uEpE{width:40%;margin:34px}}@media only screen and (min-width: 1024px){.Dashboard_container__26o_0 .Dashboard_card__3uEpE{width:30%;margin:14px}}@media only screen and (min-width: 1100px){.Dashboard_container__26o_0 .Dashboard_card__3uEpE{width:20%;margin:34px}}

